import React, { useState, useCallback } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function App() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [files, setFiles] = useState([]);
  const [hashResults, setHashResults] = useState([]);
  const [hashOption, setHashOption] = useState('local'); // ハッシュ計算オプションの状態

  // タイマーを設定して1秒ごとに時刻を更新
  React.useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    // ファイル選択後にすぐにハッシュ結果をクリアし、ファイルのステートを更新
    setHashResults([]);
    const mappedFiles = acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    }));
    setFiles(mappedFiles);
  
    // ローカルでハッシュを計算するオプションが選択されている場合は、ハッシュ計算を実行します。
    if (hashOption === 'local') {
      mappedFiles.forEach(file => calculateAndSetHash(file));
    }
  }, [hashOption]); // hashOptionを依存関係に追加します。

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });

  // calculateHash関数にログ出力を追加
  const calculateHash = async (file) => {
    console.log(`Calculating hash for file: ${file.name}`);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const content = e.target.result;
        console.log(`File loaded, size: ${content.byteLength} bytes`);

        crypto.subtle.digest('SHA-256', content)
          .then(hashBuffer => {
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
            console.log(`Hash calculated: ${hashHex}`);
            resolve(hashHex);
          })
          .catch(error => {
            console.error(`Error calculating hash: ${error}`);
            reject(error);
          });
      };

      reader.onerror = function (error) {
        console.error(`Error reading file: ${file.name}`, error);
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  // ハッシュ計算と結果設定のための新しい関数
  const calculateAndSetHash = async (file) => {
    try {
      const hash = await calculateHash(file);
      setHashResults(prevHashResults => [...prevHashResults, { name: file.name, hash: hash }]);
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  // ファイルが選択されたときのプレビューを表示するためのコンポーネント
  const filesPreview = files.map((file) => (
    <div key={file.name}>
      {file.name} - {file.size} bytes
    </div>
  ));

// `handleSubmit`関数を更新
const handleSubmit = async (event) => {
  event.preventDefault();
  if (files.length === 0) {
    alert("ファイルが選択されていません。");
    return;
  }

  if (hashOption === 'local') {
    try {
      // すべてのファイルのハッシュ値を計算します。
      const hashPromises = files.map(file => calculateHash(file));
      const hashes = await Promise.all(hashPromises);

      // 計算されたハッシュを持つオブジェクトの配列を作成します。
      const results = files.map((file, index) => ({
        name: file.name,
        size: file.size,
        hash: hashes[index]
      }));

      // 結果をステートに設定します。
      setHashResults(results);

      // リモートサーバーに結果を送信します。
      const response = await fetch('https://api-a.util.jp/timeproof/v1/stamp/browser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ files: results })
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log('Response from server:', responseData);
      alert('ハッシュ値をリモートサーバーに送信しました。');

    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  } else {
    // リモートでハッシュを計算するオプションが選択されたときの処理をここに追加します。
  }
};


  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom>
          TimeProof
        </Typography>
        <Typography variant="h5">
          {currentTime.toLocaleString('ja-JP')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset">
            <FormLabel component="legend">ハッシュ計算オプション</FormLabel>
            <RadioGroup row aria-label="hash-option" name="hash-option" value={hashOption} onChange={(e) => setHashOption(e.target.value)}>
              <FormControlLabel value="local" control={<Radio />} label="ローカルでハッシュを計算" />
              <FormControlLabel value="remote" control={<Radio />} label="リモートでハッシュを計算" />
            </RadioGroup>
          </FormControl>
          <Box {...getRootProps({ sx: { border: '2px dashed black', p: 4, mt: 3 } })}>
            <input {...getInputProps()} />
            <CloudUploadIcon sx={{ fontSize: 48 }} />
            <p>ファイルをここにドラッグ＆ドロップ、またはクリックして選択してください。</p>
          </Box>
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
            Stamp
          </Button>
        </form>
        {files.length > 0 && (
        <div>
          <h3>選択されたファイル:</h3>
          <div>{filesPreview}</div>
        </div>
        )}
        {hashResults.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 4, maxHeight: 600 }}>
          <Table sx={{ minWidth: 650 }} aria-label="hash results table">
            <TableHead>
              <TableRow>
                <TableCell>ファイル名</TableCell>
                <TableCell align="right">ハッシュ値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hashResults.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.hash}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        )}
      </Box>
    </Container>
  );
}
